





































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import RatingStars from '~/components/RatingStars/RatingStars.vue';
import { CategoryTree } from '~/composables';

export default defineComponent({
  name: 'CategoryExpertReviews',
  components: {
    SvgImage,
    RatingStars,
  },

  props: {
    category: {
      type: Object as PropType<CategoryTree>,
      default: () => ({}),
    },
  },

  setup(props) {
    const parentName = computed(() => `${props.category.breadcrumbs?.[props.category.breadcrumbs.length - 1]?.category_name} ${props.category.name}`);

    return {
      parentName,
    };
  },
});
